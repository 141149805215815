// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  defaultUrlServer: 'https://api-staging.dohu.dev/mds/',
  deployId: '8b40d9df-b526-4277-9c62-f2fd07016fa6',
  filePath: 'https://api-staging.dohu.dev/mds_file/',
  authServer: 'https://api-staging.dohu.dev/auth/',
  saasServer: 'https://api-staging.dohu.dev/saas/',
  signalR: 'https://api-staging.dohu.dev/saas_hub',
	notifyServer: 'wss://ws-staging.dohu.dev/notify',
};
